import React, {Component} from 'react';
import { Link } from "react-router-dom";
import classes from "./Header.module.scss";
import RentalItems from "../../hoc/Rentals/RentalLoop";
const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>);

class NavItems extends Component {
  render() {
    let ukNavDD = this.props.where === "header" ? "uk-navbar-dropdown" : "";
    let ukDDnav = this.props.where === "header" ? "uk-nav uk-navbar-dropdown-nav" : "";

    const rentalDrop = RentalItems.map((item) =>
      <li key={item.id} className={classes.dropLi}>
        <a href={item.link}>{item.title}</a>
      </li>
    );
    const rentalLi = (
      <li key="02" className={[classes.navItem, classes.withDrop, classes.iitems].join(' ')}>
        <Link to="#" className={classes.parentRule}>Rentals &amp; Services {dropIcon}</Link>
        <div className={[classes.dropWrap, ukNavDD].join(' ')}>
          <ul className={[classes.menuDrop, ukDDnav].join(' ')}>
            {rentalDrop}
          </ul>
        </div>
      </li>
    );
    
    return(
      <ul className={[classes[this.props.navClass], classes[this.props.addClass]].join(' ')}>
        {this.props.hideHome !== "true" ? <li key="01" className={[classes.navItem, classes.ihome].join(' ')}><Link to="/">Home</Link></li>: null}
        {this.props.altOrder !== "true" ? [rentalLi] : null}
        {this.props.hideGeneral !== "true" ? <li key="03" className={[classes.navItem, classes.iagree].join(' ')}><Link to="/rental-agreement">Rental Agreement</Link></li>: null}
        {this.props.hideGeneral !== "true" ? <li key="04" className={[classes.navItem, classes.icontact].join(' ')}><a href="#contactModal" data-uk-toggle="target: #contactModal">Contact</a></li>: null}
        {this.props.altOrder === "true" ? [rentalLi] : null}
      </ul>
    );
  }
}
export default NavItems;