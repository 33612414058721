const rentalItems = [
  {id:1, title:"All Rentals & Services", brTitle:[<>All Rentals <br/>&amp; Services</>], handle:"rentals-services", link:"https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/?full-items=yes", level:'2'},
  {id:2, title:"Packages", handle:"packages", link:"https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/?full-items=yes&flow=910867", level:'1'},
  {id:3, title:"Beach Gear", handle:"beach-gear", link:"https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/?full-items=yes&flow=918648", level:'1'},
  {id:4, title:"Kayaks & Paddleboards", handle:"kayaks-paddleboards", link:"https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/?full-items=yes&flow=918653", nickname:"Watersports", level:'1'},
  {id:5, title:"Boat Charters", brTitle:[<>Boat <br/> Charters</>], handle:"boat-charters", link:"https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/items/444286/?full-items=yes&flow=909830", level:'2'},
  {id:6, title:"Private Yoga Class", brTitle:[<>Private <br/> Yoga Class</>], footTitle:"Yoga", handle:"yoga", link:"https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/items/444292/?full-items=yes&flow=909830", level:'2'},
  {id:7, title:"Grocery Delivery", brTitle:[<>Grocery <br/> Delivery</>], handle:"grocery-delivery", link:"grocery-delivery", pageDesc:"", level:'2'},
  {id:8, title:"Airbnb Rentals", brTitle:[<>Airbnb <br/> Rentals</>], footTitle:"Airbnb", handle:"airbnb", link:"airbnb", pageDesc:"", level:'2'},
  {id:9, title:"Gift Cards", brTitle:[<>Gift <br/> Cards</>], handle:"gift", link:"https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/items/444333/?full-items=yes&flow=909830", level:'2'},
];
export default rentalItems;