// import React from "react";
import React, {Component} from 'react';
import classes from "./Shared.module.scss";

class BookNow extends Component {
  render() {
    return (
      <a className={[classes.bookBtn, classes[this.props.addClass], classes[this.props.where]].join(' ')} href={this.props.href}>
        {this.props.linkText} 
      </a>
    )
  }
}
BookNow.defaultProps = {
  addClass: 'bkLink',
  href: "https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/?full-items=yes",
  linkText: "Book Now!"
}
export default BookNow;